
<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="social-link-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
          <h4 class="">Default Social Link/List</h4>
        </div>
            <div class="accordion" id="socialLinkAccordion">

              <div class="card">
                <div class="card-header" id="settingDefaultCompanySocialLinkFacebookHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultCompanySocialLinkFacebookCollapse" aria-expanded="false" aria-controls="collapseDefaultCompanySocialLinkFacebook">

                    <span class="text-capitalize social-link-setting-title"> Company Social Link Facebook</span>

                  </button>
                </div>
                <div id="settingDefaultCompanySocialLinkFacebookCollapse" class="collapse" aria-labelledby="settingDefaultCompanySocialLinkFacebookHeading" data-parent="#socialLinkAccordion">
                  <div class="card-body">
                    <SettingDefaultCompanySocialLinkFacebook :default-company-social-link-facebook="this.defaultCompanySocialLinkFacebook"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultCompanySocialLinkLinkedinHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultCompanySocialLinkLinkedinCollapse" aria-expanded="false" aria-controls="collapseDefaultCompanySocialLinkLinkedin">

                    <span class="text-capitalize social-link-setting-title"> Company Social Link Linkedin</span>

                  </button>
                </div>
                <div id="settingDefaultCompanySocialLinkLinkedinCollapse" class="collapse" aria-labelledby="settingDefaultCompanySocialLinkLinkedinHeading" data-parent="#socialLinkAccordion">
                  <div class="card-body">
                    <SettingDefaultCompanySocialLinkLinkedin :default-company-social-link-linkedin="this.defaultCompanySocialLinkLinkedin"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultCompanySocialLinkYoutubeHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultCompanySocialLinkYoutubeCollapse" aria-expanded="false" aria-controls="collapseDefaultCompanySocialLinkYoutube">

                    <span class="text-capitalize social-link-setting-title"> Company Social Link Youtube</span>

                  </button>
                </div>
                <div id="settingDefaultCompanySocialLinkYoutubeCollapse" class="collapse" aria-labelledby="settingDefaultCompanySocialLinkYoutubeHeading" data-parent="#socialLinkAccordion">
                  <div class="card-body">
                    <SettingDefaultCompanySocialLinkYoutube :default-company-social-link-youtube="this.defaultCompanySocialLinkYoutube"/>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header" id="settingDefaultCompanySocialLinkInstagramHeading">
                  <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                          data-target="#settingDefaultCompanySocialLinkInstagramCollapse" aria-expanded="false" aria-controls="collapseDefaultCompanySocialLinkInstagram">

                    <span class="text-capitalize social-link-setting-title"> Company Social Link Instagram</span>

                  </button>
                </div>
                <div id="settingDefaultCompanySocialLinkInstagramCollapse" class="collapse" aria-labelledby="settingDefaultCompanySocialLinkInstagramHeading" data-parent="#socialLinkAccordion">
                  <div class="card-body">
                    <SettingDefaultCompanySocialLinkInstagram :default-company-social-link-instagram="this.defaultCompanySocialLinkInstagram"/>
                  </div>
                </div>
              </div>

            </div>

      </section>
    </template>
  </AppLayout>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import AppLayout from "@/layouts/backEnd/AppLayout";
import {mapActions, mapGetters} from "vuex";
import SettingDefaultCompanySocialLinkLinkedin from "@/views/backEnd/settings/default/socialLinkDetails/includes/SettingDefaultCompanySocialLinkLinkedin";
import SettingDefaultCompanySocialLinkFacebook from "@/views/backEnd/settings/default/socialLinkDetails/includes/SettingDefaultCompanySocialLinkFacebook";
import SettingDefaultCompanySocialLinkYoutube from "@/views/backEnd/settings/default/socialLinkDetails/includes/SettingDefaultCompanySocialLinkYoutube";
import SettingDefaultCompanySocialLinkInstagram from "@/views/backEnd/settings/default/socialLinkDetails/includes/SettingDefaultCompanySocialLinkInstagram";

export default {
  name: "SettingDefaultSocialLinkDetailList.vue",
  mixins: [ShowToastMessage, Loader],
  components: {
    AppLayout,
    SettingDefaultCompanySocialLinkLinkedin,
    SettingDefaultCompanySocialLinkFacebook,
    SettingDefaultCompanySocialLinkYoutube,
    SettingDefaultCompanySocialLinkInstagram,
  },
  data() {
    return {
      getSettingsParams: {
        type: ["default"],
        key: [
          "default_company_social_link_facebook",
          "default_company_social_link_linkedin",
          "default_company_social_link_youtube",
          "default_company_social_link_instagram",
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      defaultCompanySocialLinkFacebook: 'appSettings/settingDefaultCompanySocialLinkFacebook',
      defaultCompanySocialLinkInstagram: 'appSettings/settingDefaultCompanySocialLinkInstagram',
      defaultCompanySocialLinkLinkedin: 'appSettings/settingDefaultCompanySocialLinkLinkedin',
      defaultCompanySocialLinkYoutube: 'appSettings/settingDefaultCompanySocialLinkYoutube',
    }),
  },
  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.social-link-setting-wrapper .accordion .card {
  overflow: visible !important;
}

.social-link-setting-title {
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
